const en = {
  release: [
    'Released: 23.07.2020',
    'Released: 16.07.2021',
    'Released: 05.05.2022',
    'Released: 15.05.2023',
  ],
  contactUs: {
    title: 'Contact us',
  },
  contributors: {
    title: 'Contributors',
  },
};

export default en;
