import React, { FC, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { GetStaticProps } from 'next';
import dynamic from 'next/dynamic';

import SeoGenerator from '@components/SeoGenerator';
import Spinner from '@components/Spinner';
import UXCoreLayout from '@layouts/UXCoreLayout';
import { getStrapiQuestions } from '@api/questions';
import { getStrapiBiases } from '@api/biases';
import { getTags } from '@api/tags';
import { getUXCoreSeo } from '@api/mainPageSeo';

import type {
  BiasType,
  QuestionType,
  StrapiBiasType,
  TagType,
} from '@local-types/data';
import { TRouter } from '@local-types/global';

import {
  mergeBiasesLocalization,
  mergeQuestionsLocalization,
} from '@lib/helpers';
import useMobile from '@hooks/useMobile';
import biasesMetadataMobile from '@data/biasesMobile';
import decisionTable from '@data/decisionTable';
import { getPersonaList } from '@api/personas';

import ToolHeader from "@components/ToolHeader";

const SavedPersonas = dynamic(() => import('@components/_uxcp/SavedPersonas'), {
  ssr: false,
});

interface UXCoreProps {
  questions: QuestionType[];
  biases: BiasType[];
  tags: TagType[];
  strapiBiases: StrapiBiasType[];
  seo: { en: any; ru: any };
  description?: string;
}

const UXCore: FC<UXCoreProps> = ({
  questions,
  tags,
  strapiBiases,
  seo,
  description,
}) => {
  const router = useRouter();
  const { locale } = router as TRouter;
  const [openPodcast, setOpenPodcast] = React.useState(false);
  const [openPersonas, setOpenPersonas] = useState<boolean>(false);
  const [personas, setPersonas] = useState(null);

  const { isMobile } = useMobile()[1];
  const { savedPersonasTitles } = decisionTable[locale];

  const seoData = useMemo(() => {
    return seo[locale];
  }, [locale]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getPersonaList();
      setPersonas(result);
    };

    fetchData().then(r => console.log(r));
  }, []);
  
  return (
    <>
      <SeoGenerator strapiSEO={seoData} />
      {!isMobile && (
        <ToolHeader
          page="uxcore"
          tags={tags}
          openPersonaModal={setOpenPersonas}
          setOpenPodcast={setOpenPodcast}
          openPodcast={openPodcast}
        />
      )}
      <UXCoreLayout
        tags={tags}
        strapiBiases={strapiBiases}
        openPodcast={openPodcast}
        setOpenPodcast={setOpenPodcast}
        description={description}
      />
      {openPersonas && (
        <SavedPersonas
          personaTableTitles={savedPersonasTitles}
          savedPersonas={personas}
          setOpenPersonas={setOpenPersonas}
          setSavedPersonas={setPersonas}
        />
      )}
      <Spinner />
    </>
  );
};

export const getStaticProps: GetStaticProps = async ({ params, locale }) => {
  const strapiQuestions = await getStrapiQuestions();
  const strapiBiases = await getStrapiBiases();
  const tags = getTags();
  const description = biasesMetadataMobile[locale].description;
  const biases = mergeBiasesLocalization(strapiBiases.en, strapiBiases.ru).sort(
    (a, b) => a.number - b.number,
  );

  const questions = mergeQuestionsLocalization(
    strapiQuestions.en,
    strapiQuestions.ru,
  ).sort((a, b) => a.number - b.number);

  const mainSeo = await getUXCoreSeo();

  return {
    props: {
      questions,
      strapiBiases: biases,
      tags,
      seo: mainSeo,
      description: description,
    },
    revalidate: 5,
  };
};

export default UXCore;
