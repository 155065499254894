const ru = {
  release: [
    'Проект запущен: 23.07.2020',
    'Проект запущен: 16.07.2021',
    'Проект запущен: 05.05.2022',
    'Проект запущен: 15.05.2023',
  ],
  contactUs: {
    title: 'Связь с нами',
  },
  contributors: {
    title: 'Участники',
  },
};

export default ru;
