import { FC, Fragment, useState } from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';

import toolFooterData from '@data/toolFooter';
import CustomModal from '@components/CustomModal';
import type { TRouter } from '@local-types/global';
import type { TagType } from '@local-types/data';

import styles from './ToolFooter.module.scss';

const releaseIndexes = {
  uxcore: 0,
  uxcg: 1,
  uxcp: 2,
  api: 3,
};

type TToolFooter = {
  page: 'uxcore' | 'uxcg' | 'uxcp' | "api";
  tags: TagType[];
};

const ToolFooter: FC<TToolFooter> = ({ page, tags }) => {
  const router = useRouter();
  const { locale } = router as TRouter;
  const { release, contactUs, contributors } = toolFooterData[locale];
  const [openedModal, setOpenedModal] = useState(null);

  const toggleModal = (modalValue?: string) => {
    setOpenedModal(modalValue || null);
  };

  const isUXCore = page === 'uxcore';
  const isUXCG = page === 'uxcg';
  const isUXCP = page === 'uxcp';
  const isAPI = page === 'api';

  return (
    <Fragment>
      <footer
        className={cn(styles.ToolFooter, {
          [styles.UXCore]: isUXCore,
          [styles.UXCG]: isUXCG,
          [styles.UXCP]: isUXCP,
          [styles.API]: isAPI,
        })}
      >
        {isUXCore && <span className={styles.Motto}>Be Kind. Do Good.</span>}
        <div className={styles.AdvancedLinks}>
          <span onClick={() => toggleModal('contributors')}>
            {contributors.title}
          </span>
          <span onClick={() => toggleModal('contactUs')}>
            {contactUs.title}
          </span>
        </div>
        <span className={styles.Release}>{release[releaseIndexes[page]]}</span>
      </footer>
      <CustomModal
        isVisible={!!openedModal}
        contentType={openedModal}
        tags={tags}
        onClose={() => toggleModal()}
      />
    </Fragment>
  );
};

export default ToolFooter;
